<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-wrap align-self-start w-100" style="gap: 12px">
        <v-btn
          @click.stop="verPDF"
          :disabled="
            !['Pagada', 'Pendiente Pago'].includes(datos.estadoFactura)
          "
          color="primary"
          small
        >
          <v-icon left>mdi-file-document-edit-outline</v-icon>Ver PDF
        </v-btn>
        <v-btn
          @click.stop="regenerarPDF"
          :disabled="
            !['Pagada', 'Pendiente Pago'].includes(datos.estadoFactura)
          "
          color="primary"
          small
          v-if="$root.acceso('DEV')"
        >
          <v-icon left>mdi-refresh</v-icon>Regenerar PDF
        </v-btn>
        <v-btn
          :disabled="
            !['Pagada', 'Pendiente Pago'].includes(datos.estadoFactura)
          "
          color="primary"
          small
          @click.stop="sendEmail(false)"
        >
          <v-icon left>mdi-send</v-icon>Enviar factura por email
        </v-btn>
        <v-btn
          :disabled="
            !['Pendiente Pago'].includes(datos.estadoFactura) &&
              !$root.acceso('DEV')
          "
          color="primary"
          small
          @click.stop="deshacerFactura"
        >
          <v-icon left>mdi-radioactive-circle</v-icon>Eliminar factura
        </v-btn>
        <v-btn
          :disabled="!['Pagada'].includes(datos.estadoFactura)"
          color="primary"
          small
          @click.stop="exportarAcumulativo(true)"
        >
          <v-icon left>mdi-calendar-export</v-icon>Exportar autofactura excel
        </v-btn>
        <v-btn
          :disabled="!['Pagada'].includes(datos.estadoFactura)"
          color="primary"
          small
          @click.stop="exportarAcumulativo(false)"
        >
          <v-icon left>mdi-database-export-outline</v-icon>Exportar acumulativo
          anual
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <tarjeta-datos
          titulo="Estado Factura"
          header
          class="flex-grow-1"
          :datos="[
            { nombre: 'Canal', valor: datos.canal },
            { nombre: 'Pago factura', valor: parseDate(datos.fechaFactura) },
            { nombre: 'Cuenta pago', valor: datos.cuentaBancaria },
            {
              nombre: 'Comentario pago',
              valor:
                datos.estadoFactura == 'Pendiente Pago'
                  ? ''
                  : datos.comentarioPago,
            },
          ]"
        >
          <v-divider />
          <v-row class="pa-4 d-inline-flex w-100">
            <v-col cols="12" class="col-md-4 font-weight-bold">
              <span>Estado factura</span>
            </v-col>
            <v-col cols="12" class="col-md-8">
              <status-chip :value="datos.estadoFactura"></status-chip>
            </v-col>
          </v-row>
        </tarjeta-datos>
      </v-col>
      <v-col>
        <tarjeta-datos
          class="flex-grow-1"
          titulo="Datos documento"
          header
          :datos="[
            { nombre: 'Razón Social', valor: datos.canal },
            { nombre: 'Nif/Cif', valor: datos.Identidad },
            { nombre: 'Fecha Factura', valor: parseDate(datos.fechaFactura) },
            { nombre: 'Número Documento', valor: datos.numeroFactura },
          ]"
        ></tarjeta-datos>
      </v-col>
      <v-col>
        <tarjeta-datos
          class="flex-grow-1"
          titulo="Datos importe"
          header
          :datos="[
            {
              nombre: 'Base Imponible',
              valor: parseFloat(datos.baseImponible).toFixed(2) + ' €',
            },
            {
              nombre: `IVA (${datos.porcentajeIva * 1}%)`,
              valor: parseFloat(datos.iva).toFixed(2) + ' €',
            },
            {
              nombre: `IRPF (${datos.porcentajeIrpf * 1}%)`,
              valor: parseFloat(datos.irpf).toFixed(2) + ' €',
            },
            {
              nombre: 'Total Factura',
              valor: parseFloat(datos.totalFactura).toFixed(2) + ' €',
            },
          ]"
        ></tarjeta-datos>
      </v-col>
    </v-row>

    <!-- <tarjeta-datos
				class="flex-grow-1"
				titulo="Datos envío"
				header
				:datos="[
					{ nombre: 'Dirección', valor: datos.CallePuntoSuministro },
					{ nombre: 'Población', valor: datos.CiudadSuministro },
					{ nombre: 'Provincia', valor: datos.ProvinciaSuministro },
					{ nombre: 'Cód.Postal', valor: datos.CodPostal },
				]"
			></tarjeta-datos>-->

    <v-row
      class="w-100 d-flex align-end"
      v-if="
        datos.estadoFactura == 'Pendiente Pago' &&
          $root.acceso('CAMBIAR_ESTADO_FACTURAS_COLABORADORES')
      "
    >
      <v-col cols="10">
        <v-textarea
          v-model="datos.comentarioPago"
          dense
          hide-details="auto"
          filled
          no-resize
          label="Comentario de Pago (Opcional)"
          :disabled="datos.lineas.length == 0"
        ></v-textarea>
      </v-col>
      <v-col cols="2">
        <v-btn
          @click.stop="pagar"
          x-large
          block
          outlined
          color="warning"
          :disabled="datos.lineas.length == 0"
          >PAGAR</v-btn
        >
      </v-col>
    </v-row>

    <v-row class="w-100" v-if="datos.estadoFactura != 'Pagada'">
      <v-col cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!addLine.show"
          color="primary"
          @click="addLine.show = true"
        >
          <v-icon left>mdi-plus</v-icon>Añadir línea
        </v-btn>
        <v-btn v-else color="error" @click="addLine.show = false">
          <v-icon left>mdi-cancel</v-icon>Cancelar línea
        </v-btn>
      </v-col>
      <v-slide-y-transition>
        <v-col cols="12" v-if="addLine.show">
          <v-form ref="nuevaLinea">
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  label="Descripción"
                  v-model="addLine.descripcion"
                  dense
                  filled
                  hide-details="auto"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Importe"
                  suffix="€"
                  dense
                  filled
                  hide-details="auto"
                  :rules="[rules.req, rules.isNumber]"
                  v-model="addLine.importe"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-spacer />
                <v-btn color="primary" @click="addLinea">
                  <v-icon left>mdi-content-save</v-icon>guardar linea
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-slide-y-transition>
    </v-row>

    <v-row>
      <v-col>
        <tarjeta-datos
          titulo="Contenido Factura"
          header
          class="pa-0 w-100"
          v-if="datos.lineas"
        >
          <v-data-table
            fixed-header
            :items="datos.lineas"
            :headers="headers"
            :calculate-widths="true"
            :loading="loading"
            item-key="idLinea"
            :single-select="false"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [10, 25, 50],
            }"
            checkbox-color="secondary"
          >
            <template v-slot:body.prepend="{ headers }">
              <table-filters
                :headers="headers"
                :items="datos.lineas"
                v-model="inlineFilters"
              ></table-filters>
            </template>
            <template v-slot:item.Situacion="{ item }">
              <status-chip v-if="item.Situacion" :value="item.Situacion" />
            </template>
            <template v-slot:item.CodigoContrato="{ item }">
              <v-tooltip v-if="item.CodigoContrato" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    link
                    :to="
                      `/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`
                    "
                    :color="Number(item.esDevolucion) ? 'error' : 'secondary'"
                    text
                    >{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn
                  >
                </template>
                <span>Detalles del contrato</span>
              </v-tooltip>
            </template>
            <template v-slot:item.CodigoCUPS="{ item }">
              <v-tooltip v-if="item.CodigoCUPS" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    link
                    :to="`/detalles_cups?cups=${item.CodigoCUPS}`"
                    :color="Number(item.esDevolucion) ? 'error' : 'secondary'"
                    text
                    >{{ item.CodigoCUPS }}</v-btn
                  >
                </template>
                <span>Detalles del CUPS</span>
              </v-tooltip>
            </template>
            <template v-slot:item.Identificador="{ item }">
              <v-tooltip v-if="item.IdCliente" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    link
                    :to="`/clientes/detalles?idCliente=${item.IdCliente}`"
                    :color="Number(item.esDevolucion) ? 'error' : 'secondary'"
                    text
                    >{{ item.Identificador }}</v-btn
                  >
                </template>
                <span>Detalles del cliente</span>
              </v-tooltip>
            </template>
            <template v-slot:item.fechaCreada="{ item }">
              <span
                v-text="parseDate(item.fechaCreada, false, true, true)"
              ></span>
            </template>
            <template v-slot:item.importeContrato="{ item }">
              <v-chip
                label
                v-text="parseFloat(item.importeContrato).toFixed(2) + '€'"
              ></v-chip>
            </template>
            <template v-slot:item.acciones="{ item }">
              <!-- <v-tooltip v-if="!Number(item.esDevolucion) && !Number(item.devueltoEn)" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="marcarDevolucion(item.idLinea, true)"
                            v-on="on"
                            v-bind="attrs"
                            icon
                            color="error"
                          >
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                        </template>
                        Marcar para devolucion
                      </v-tooltip>
                      <v-tooltip v-else-if="!Number(item.devueltoEn)" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="marcarDevolucion(item.idLinea, false)"
                            v-on="on"
                            v-bind="attrs"
                            icon
                            color="success"
                          >
                            <v-icon>mdi-redo</v-icon>
                          </v-btn>
                        </template>
                        Desmarcar para devolucion
                      </v-tooltip> -->
              <v-tooltip
                v-if="!item.CodigoContrato && datos.estadoFactura != 'Pagada'"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="eliminarLinea(item.idLinea)"
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="error"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Eliminar línea
              </v-tooltip>
            </template>
          </v-data-table>
        </tarjeta-datos>
      </v-col>
    </v-row>

    <v-dialog persistent max-width="450" v-model="p.show">
      <v-card class="pa-2">
        <v-form ref="formDevolucion">
          <v-text-field
            suffix="€"
            label="Importe devolución"
            filled
            dense
            hide-details="auto"
            v-model="p.importeDevolucion"
            :rules="[rules.req, rules.isNumber]"
          ></v-text-field>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="p.reject" text color="error">Cancelar</v-btn>
          <v-btn @click="p.resolve" text color="primary">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      max-width="650"
      v-if="pagarDialog.show"
      :key="pagarDialog.show"
      :value="pagarDialog.show"
    >
      <v-card>
        <v-card-title>
          ¿Estás seguro de dar por pagada esta factura?
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="mt-12">
          <v-spacer />
          <v-btn @click="pagarDialog.reject" text color="error">Cancelar</v-btn>
          <v-btn @click="pagarDialog.resolve" text color="primary"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="1600" :value="emailDialog.show && emailDialog.pdf">
      <v-form
        class="fill-height"
        ref="form"
        @submit.prevent="$refs.form.validate() ? emailDialog.resolve() : null"
      >
        <v-card class="pa-2 d-flex flex-column" height="800">
          <v-row class="w-100 flex-grow-1">
            <v-col cols="12" md="8" class="d-flex flex-column">
              <iframe
                :src="emailDialog.pdf"
                class="w-100 flex-grow-1"
                frameborder="0"
              ></iframe>
            </v-col>
            <v-col cols="12" md="4">
              <v-toolbar dense color="primary">
                <v-toolbar-title class="white--text"
                  >Envio de correo automático</v-toolbar-title
                >
              </v-toolbar>
              <aux-input title="CANAL">
                <v-text-field
                  dense
                  filled
                  hide-details
                  readonly
                  v-model="datos.canal"
                ></v-text-field>
              </aux-input>

              <aux-input title="Correos a enviar">
                <v-text-field
                  dense
                  filled
                  hide-details
                  readonly
                  v-model="datos.Correo"
                ></v-text-field>
              </aux-input>

              <aux-input title="Correos en copia">
                <div>
                  <v-text-field
                    v-for="(correo, index) in datos.CorreosCopia"
                    :key="correo"
                    dense
                    filled
                    hide-details="auto"
                    v-model="datos.CorreosCopia[index]"
                    class="mb-1"
                    :rules="[rules.email, rules.req]"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        class="mt-n1"
                        icon
                        color="error"
                        @click="datos.CorreosCopia.splice(index, 1)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                  <div class="d-flex justify-end">
                    <v-btn
                      color="primary"
                      small
                      fab
                      @click="datos.CorreosCopia.push(null)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </aux-input>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="emailDialog.reject" text color="error"
              >Cancelar</v-btn
            >
            <v-btn type="submit" color="primary">
              <v-icon left>mdi-send</v-icon>Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter, downloadFile } from "@/utils/index.js";
import { req, isNumber, email } from "@/utils/validations.js";

export default {
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    DetallesContrato: () =>
      import("@/modules/contratos/views/DetallesContrato.vue"),
  },
  props: {
    idFactura: Number | String,
  },
  data() {
    return {
      loading: false,
      items: [],
      inlineFilters: {},
      rules: { req, isNumber, email },
      emailDialog: {
        show: null,
        resolve: null,
        reject: null,
      },
      p: {
        show: false,
        reject: null,
        resolve: null,
        importeDevolucion: null,
      },
      pagarDialog: {
        show: false,
        reject: null,
        resolve: null,
      },
      addLine: {
        show: false,
        descripcion: null,
        importe: null,
      },
      headers: [
        // { text: "Línea", value: "Linea" },
        { text: "Situacion", value: "Situacion", dataType: "select" },
        { text: "Fecha", value: "fechaCreada", dataType: "date" },
        { text: "Descripción", value: "descripcion" },
        { text: "Contrato", value: "CodigoContrato" },
        { text: "CUPS", value: "CodigoCUPS" },
        { text: "Cliente", value: "Identificador" },
        { text: "Nombre Cliente", value: "denominacion" },
        { text: "Importe", value: "importeContrato" },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          filterable: false,
        },
      ].map((header) => {
        return {
          class: "text-no-wrap sticky-header",
          cellClass: "pa-2 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        };
      }),
      datos: {
        CorreosCopia: ["canales@alumbraenergia.es"],
      },
    };
  },
  mounted() {
    this.getFacturaDatos();
  },
  methods: {
    parseDate,
    getFacturaDatos() {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/autoFacturas.php`,
        params: {
          token: this.$store.getters.getJwtEmpresa,
          idFactura: this.idFactura,
        },
      })
        .then((res) => {
          if (this.datos.CorreosCopia && this.datos.CorreosCopia.length > 0) {
            res.data.CorreosCopia = this.datos.CorreosCopia;
          }
          this.datos = {
            ...res.data,
            lineas: res.data.lineas.map((l) => ({
              ...l,
              denominacion: [
                l.RazonSocialTitular ||
                  [l.NombreTitular, l.Apellido1Titular, l.Apellido2Titular]
                    .filter((i) => !!i)
                    .join(" "),
              ],
              esDevolucion: Number(l.esDevolucion) || Number(l.devueltoEn),
            })),
          };
        })
        .catch((err) => {
          console.error(err);
          console.error(err.response);
        });
    },
    verPDF() {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
        params: {
          token: this.$store.getters.getJwtEmpresa,
          archivo: this.datos.archivo,
        },
        responseType: "blob",
      })
        .then((res) => {
          downloadFile(
            new File([res.data], this.datos.archivo, {
              type: "application/pdf",
            }),
            true
          );
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "Ha ocurrido un error");
        });
    },
    async pagar() {
      try {
        await new Promise((resolve, reject) => {
          this.pagarDialog.show = true;
          this.pagarDialog.resolve = resolve;
          this.pagarDialog.reject = reject;
        });

        this.pagarDialog.show = false;
        this.pagarDialog.resolve = null;
        this.pagarDialog.reject = null;

        await axios({
          method: "PUT",
          url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
          data: {
            token: this.$store.getters.getJwtEmpresa,
            idFactura: this.datos.idFactura,
            comentario: this.datos.comentarioPago,
          },
        });
        this.$emit("reload");
        this.getFacturaDatos();
        this.sendEmail(true);
      } catch (err) {
        console.error(err);
        this.pagarDialog.show = false;
        this.pagarDialog.resolve = null;
        this.pagarDialog.reject = null;
      }
    },
    async marcarDevolucion(idLinea, devolver) {
      if (!devolver) {
        await axios({
          method: "PUT",
          url: `${process.env.VUE_APP_OUR_API_URL}/devoluciones.php`,
          data: {
            token: this.$store.getters.getJwtEmpresa,
            idLinea,
            devolver: devolver ? 1 : 0,
            importeDevolucion: null,
          },
        });
        this.$emit("reload");
        this.getFacturaDatos();

        return;
      }

      await new Promise((resolve, reject) => {
        this.p.importeDevolucion =
          this.datos.lineas.find((l) => l.idLinea == idLinea).importeContrato *
          -1;
        this.p.show = true;
        this.p.resolve = resolve;
        this.p.reject = reject;
      })
        .then(async (res) => {
          await axios({
            method: "PUT",
            url: `${process.env.VUE_APP_OUR_API_URL}/devoluciones.php`,
            data: {
              token: this.$store.getters.getJwtEmpresa,
              idLinea,
              devolver: devolver ? 1 : 0,
              importeDevolucion: Math.abs(this.p.importeDevolucion) * -1,
            },
          });
          this.p.show = false;
          this.$emit("reload");
          this.getFacturaDatos();
        })

        .catch((err) => {
          this.p.show = false;
          return;
        });
    },
    async addLinea() {
      if (!this.$refs.nuevaLinea.validate()) return;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OUR_API_URL}/lineasFactura.php`,
        data: {
          token: this.$store.getters.getJwtEmpresa,
          idFactura: this.datos.idFactura,
          descripcion: this.addLine.descripcion,
          importe: this.addLine.importe,
        },
      });

      this.$root.$emit("snack", "Línea añadida correctamente");
      this.addLine = { show: false };
      this.regenerarPDF();
      this.getFacturaDatos();
    },
    async eliminarLinea(idLinea) {
      await axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_OUR_API_URL}/lineasFactura.php`,
        data: {
          token: this.$store.getters.getJwtEmpresa,
          idLinea,
        },
      });

      this.$root.$emit("snack", "Línea eliminada correctamente");
      this.addLine = { show: false };
      this.regenerarPDF();
      this.getFacturaDatos();
    },
    deshacerFactura() {
      if (
        !confirm(
          `¿Estás seguro de que quieres eliminar la factura ${this.datos.numeroFactura}?`
        )
      )
        return;
      axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
        data: {
          token: this.$store.getters.getJwtEmpresa,
          idFactura: this.datos.idFactura,
        },
      }).then((res) => {
        this.$root.$emit("snack", "Factura eliminada correctamente");
        this.$emit("reload");
        this.$emit("close");
      });
    },
    async regenerarPDF() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/generar_factura.php`,
        headers: {
          generate: true,
        },
        params: {
          idFactura: this.datos.idFactura,
          token: this.$store.getters.getJwtEmpresa,
        },
      });
      this.$root.$emit("snack", "PDF regenerado correctamente");
    },
    exportarAcumulativo(soloEsta) {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/acumulativoAnual.php`,
        responseType: "blob",
        params: {
          canal: [this.datos.canal],
          token: this.$store.getters.getJwtEmpresa,
          idFactura: soloEsta ? this.datos.idFactura : null,
        },
      }).then((res) => {
        const MESES = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];
        let excel = new File(
          [res.data],
          `Acumulativo_comisiones_${this.datos.canal}_${
            MESES[new Date().getMonth()]
          }_${new Date().getFullYear()}.xlsx`,
          {
            type: "application/excel",
          }
        );
        downloadFile(excel, false);
      });
    },
    async sendEmail(skip = false) {
      return;
      // const promiseHelper = () => {
      //   return new Promise(async (resolve, reject) => {
      //     axios({
      //       method: "GET",
      //       url: `${process.env.VUE_APP_OUR_API_URL}/autofacturar.php`,
      //       params: {
      //         token: this.$store.getters.getJwtEmpresa,
      //         archivo: this.datos.archivo,
      //       },
      //       responseType: "blob",
      //     })
      //       .then((res) => {
      //         this.emailDialog.pdf = URL.createObjectURL(
      //           new File([res.data], this.datos.archivo, {
      //             type: "application/pdf",
      //           }),
      //           true
      //         );
      //         this.emailDialog.show = true;
      //         this.emailDialog.resolve = resolve;
      //         this.emailDialog.reject = reject;
      //       })
      //       .catch((err) => {
      //         console.error(err);
      //         this.$root.$emit("snack", "Ha ocurrido un error");
      //         reject();
      //       });
      //   });
      // };

      // try {
      //   if (!skip) await promiseHelper();
      //   const { data } = await axios({
      //     url: `${process.env.VUE_APP_OUR_API_URL}/enviar_autofactura.php`,
      //     method: "GET",
      //     params: {
      //       token: this.$store.getters.getJwtEmpresa,
      //       dest: [...this.datos.CorreosCopia, this.datos.Correo],
      //       idFactura: this.datos.idFactura,
      //     },
      //   });
      //   console.log({ data });
      //   this.emailDialog.show = false;
      //   this.emailDialog.resolve = null;
      //   this.emailDialog.reject = null;
      //   this.$root.$emit("snack", "Se ha enviado por email correctamente");
      // } catch (err) {
      //   console.log(err);
      //   this.emailDialog.show = false;
      //   this.emailDialog.resolve = null;
      //   this.emailDialog.reject = null;
      //   this.$root.$emit(
      //     "snack",
      //     "No se ha podido realizar el aviso por email"
      //   );
      // }
    },
  },
};
</script>

<style>
.pagar-btn {
  min-height: 150px;
  display: flex;
  place-items: center;
  place-content: center;
  font-size: 3rem;
}
</style>
